import * as React from 'react'
import Modal from 'react-modal'

import Layout from 'layouts/main'
import { navigate } from 'gatsby-link'
import ResetPassword from '../components/resetPassword/resetPassword'
import axios from 'axios'
import { config } from '../config'
import queryString from 'query-string'

const ResetPage = () => {
  // const [resetModalOpen, setResetModalOpen] = React.useState(true)

  const resetPassword = async password => {
    try {
      const { token } = queryString.parse(window.location.search)

      let { success, error } = (
        await axios.post(`${config.API_BASE_URL}/users/reset`, {
          token,
          password,
        })
      ).data
      if (success) {
        alert('Your password has been successfully changed')
        window.location.href = '/'
      } else {
        alert(error)
      }
    } catch (e) {
      alert('An error has occurred. Please try again later')
    }
  }

  return (
    <Layout>
      {({ setRegModalIsOpen, regModalIsOpen }) => (
        <Modal
          isOpen={!regModalIsOpen}
          contentLabel={'Registration Modal'}
          ariaHideApp={false}
          className="react_modal_centered"
          overlayClassName="react_modal_register_overlay"
          closeTimeoutMS={1000}
        >
          <ResetPassword
            onClose={() => navigate('/')}
            onSignup={resetPassword}
            onFinish={() => navigate('/')}
          />
        </Modal>
      )}
    </Layout>
  )
}

export default ResetPage
