import * as React from 'react'
import styled from 'styled-components'

import SubscriptionCard from '../subscription/subscriptionCard'
import { Input } from '../styled/input'
import { Button } from '../styled/button'
import { Styledlink } from '../styled/typography'
import { useState } from 'react'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    margin: 8px 0;
    flex: 1 1 80%;
  }
  > button {
    width: 100%;
    margin: calc(var(--spacing) * 3) 0;
  }
`

const ResetPassword = ({ onClose, onSignup }) => {
  const onReset = () => {
    if (!password) {
      alert('Please enter a password')
      return
    }
    if (confirmPassword !== password) {
      alert('Passwords do not match')
      return
    }
    onSignup(password)
  }

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  return (
    <SubscriptionCard
      titles={[
        { inverted: true, content: 'RESET' },
        { inverted: false, content: 'PASSWORD' },
      ]}
      isLeft={false}
      onClose={onClose}
    >
      <Container>
        <Input
          label="Password"
          type="password"
          onChange={e => setPassword(e.target.value)}
          placeholder="••••••••"
          value={password}
        />
        <Input
          label="Repeat Password"
          type="password"
          onChange={e => setConfirmPassword(e.target.value)}
          placeholder="••••••••"
          value={confirmPassword}
        />
        <Button onClick={onReset}>Reset</Button>
      </Container>
    </SubscriptionCard>
  )
}

export default ResetPassword
